import { FeatureData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../components/View';
import Form from '../../../../../containers/Espace/Form';
import params from '../../../../../params/user/group.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const PageEspaceContactsGroupeCreate: FC<
  PageProps & EspaceProps & UserProps
> = ({ espace, user, params: { espaceId } }) => {
  const model = new FeatureData({
    espaceId,
    params,
    collectionName: 'groupes',
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          itemPathnamePrefix={`/espaces/${espaceId}/contacts/groupes/`}
          model={model}
          name="groupe"
          type="create"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceContactsGroupeCreate);
